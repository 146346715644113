import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SiteWrapper from '../../SiteWrapper';
import { Page, Card } from 'tabler-react';
import Graph from '../../components/Sparql/Graph'
import { Collapse, Button } from 'reactstrap';

const TiddlyMapExport = ({ match: { params: { id: tiddlyMapExportId } } }) => {
  const [mapExport, setMapExport] = useState()
  const [tiddlyMapView, setTiddlyMapView] = useState()
  const [visibleExportGraphIds, setVisibleExportGraphIds] = useState([])
  const [visibleErrorType, setVisibleErrorType] = useState()

  useEffect(() => {
    mapExport && mapExport.attributes.tiddly_map_view_id && axios.get(`/api/tiddly_map_views/${mapExport.attributes.tiddly_map_view_id}`)
      .then(({ data: { data } }) => setTiddlyMapView(data))
  }, [mapExport])

  useEffect(() => {
    axios.get(`/api/tiddly_map_exports/${tiddlyMapExportId}`)
      .then(({ data: { data } }) => setMapExport(data))
  }, [tiddlyMapExportId])

  const displayEdge = (edge) => {
    const fromLabel = mapExport.attributes.conversion_log.nodes[edge.from].label
    const toLabel = mapExport.attributes.conversion_log.nodes[edge.to].label
    return <h4>
      <span className="mx-2 badge badge-pill badge-primary">{fromLabel}</span>
      <span className="mx-2 badge badge-pill badge-secondary">{edge.type}</span>
      <span className="mx-2 badge badge-pill badge-info">{toLabel}</span>
    </h4>
  }

  const REGEXP_ID = useMemo(() => /"*([\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2})*"*.*\/([^/>]+)>?\.?$/, [])


  const generateNodeID = useCallback((node) => {
    const res = REGEXP_ID.exec(node);
    if (!res || res.length < 3) { return new Date().getTime() }
    return res[2];
  }, [REGEXP_ID])

  const getNodeData = useCallback((node) => {
    const nodeId = generateNodeID(node)
    return nodeId
      ? mapExport.attributes.conversion_log.nodes[nodeId]
      : null
  }, [mapExport, generateNodeID])

  const displayNode = (node) => {
    const titles = []
    const nodeData = getNodeData(node);
    let hasErrors = false;
    titles.push(nodeData && nodeData.label)
    hasErrors = !(nodeData)
    const hasTitle = titles.filter(n => n).length
    return <td
      title={titles.join(<br />)}
      className={hasErrors ? 'text-error' : undefined}
    >
      {hasTitle
        ? titles.join(<br />)
        : node
      }<br />
      {hasTitle ? <span style={{ opacity: 0.5 }}>{node}</span> : null}
    </td>
  }

  const displayNtuple = (ntuple) => {
    const { 0: subject, 1: predicate, 2: object } = ntuple.replace(/\.$/, '').split(' ')

    return <tr key={ntuple}>
      {displayNode(subject)}
      <td title={predicate}>{predicate}</td>
      {displayNode(object)}
    </tr>
  }

  const getNodesFromEdge = (edge) => {
    return [edge.from, edge.to].map(nodeId => mapExport.attributes.conversion_log.nodes[nodeId])
  }

  const ntuplesToEdges = (tuples) => {
    return tuples.map(ntuple => {
      const { 0: from, 1: label, 2: to } = ntuple.replace(/\.$/, '').split(' ')
      const fromId = generateNodeID(from) || []
      const toId = generateNodeID(to) || []
      return { from: fromId || from, label, to: toId || to }
    })
  }

  const ntuplesToNodes = (tuples) => {
    const nodes = []
    tuples.forEach(ntuple => {
      const n = [];
      const { 0: from, 2: to } = ntuple.replace(/\.$/, '').split(/[\s]/)
      n.push(from)
      n.push(to)
      n.forEach(node => {
        let nodeData = getNodeData(node)
        if (nodeData) {

          if (!nodes.find(n => n.id === nodeData.id)) {
            nodes.push(nodeData)
          }
        } else {
          const nodeId = generateNodeID(node) || []
          const rawData = { id: nodeId || node, label: node }
          if (!nodes.find(n => n.id === rawData.id)) {
            nodes.push(rawData)
          }
        }
      })
    })
    return nodes;
  }

  const errorMap = useMemo(() => mapExport && mapExport.attributes && mapExport.attributes.missing_log.reduce((prev, cur) => {
    switch (cur.error) {
      case 'Edge label not found in matrix':
      case 'Node label not found in matrix':
        prev.matrix.push(cur);
        break;
      case 'Node type not found in Ontology':
      case 'Edge type not found in Ontology':
        prev.ontology.push(cur);
        break;
      case 'Node attribute type unknown':
      case 'Edge attribute type unknown':
        prev.attributes.push(cur);
        break;
      default:
        console.error('Unknown error type: ', cur)
    }
    return prev;
  }, { matrix: [], ontology: [], attributes: [] }),
    [mapExport]);
  console.log(errorMap)
  return (
    <SiteWrapper>
      <Page.Content title="ExG">
        <Card>
          <Card.Body>
            <h4 className="mr-3">Erreurs: </h4>
            {errorMap && Object.keys(errorMap).map(errorType => (
              <Button color={errorMap[errorType].length ? "danger" : 'success'} disabled={!errorMap[errorType].length} onClick={() => setVisibleErrorType(errorType)} style={{ marginBottom: '1rem' }}>{errorType} ({errorMap[errorType].length})</Button>
            ))}
            <div class="row">
              {mapExport && mapExport.attributes.missing_log.length && Object.keys(errorMap).map(errorType =>
                <Collapse isOpen={visibleErrorType === errorType} className="col-12">

                  <table
                    className="table table-bordered table-danger" id={`${errorType}Errors`}
                  >
                    <thead>
                      <tr>
                        <th className="text-danger">Éléments manquant</th>
                        <th className="text-danger">Erreur</th>
                        <th className="text-danger">Type recherché (OWL)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {errorMap[errorType].map(data => (
                        <tr>
                          <td>{data.label}</td>
                          <td>{data.error}</td>
                          <td>{data.nodeType}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Collapse>
              )
              }
            </div>
            </Card.Body>
            <Card.Body>

            <h4 className="mr-3">Conversions: </h4>

            {mapExport && mapExport.attributes.conversion_log.conversions && mapExport.attributes.conversion_log.conversions.map(log => (
              <div className="row" key={log.edge.id}>
                <div className="col-12">{displayEdge(log.edge)}</div>
                { console.log({ visibleExportGraphIds, id: log.edge.id }) ||
                  visibleExportGraphIds.includes(log.edge.id)
                  ?
                  <div className="col-12">
                    <a href="#showGraph"
                      className="float-right"
                      onClick={() => setVisibleExportGraphIds(visibleExportGraphIds.filter(id => id !== log.edge.id))}
                    >
                      Cacher le graph
                    </a>
                    <div className="row">
                      <div className="col-6"><Graph edges={[log.edge]} nodes={getNodesFromEdge(log.edge)} /></div>
                      <div className="col-6"><Graph
                        edges={ntuplesToEdges(log.ntuples)}
                        nodes={ntuplesToNodes(log.ntuples)}
                      /></div>
                    </div>
                  </div>
                  : <div className="col-12">
                    <a href="#showGraph"
                      className="float-right"
                      onClick={() => setVisibleExportGraphIds([...visibleExportGraphIds, log.edge.id])}
                    >
                      Afficher sous forme de graph
                      </a>
                  </div>
                }

                <table
                  className="table table-bordered"
                >
                  <thead>
                    <tr>
                      <th width="35%">Subject</th>
                      <th width="30%">Predicate</th>
                      <th width="35%">Object</th>
                    </tr>
                  </thead>
                  <tbody>
                    {log.ntuples.map(ntuple => displayNtuple(ntuple))}
                  </tbody>
                </table>
              </div>
            ))}
          </Card.Body>
        </Card>
      </Page.Content>
    </SiteWrapper>)
}

export default TiddlyMapExport