import { Page, Card } from 'tabler-react';
import SiteWrapper from '../SiteWrapper';

const OntologyLoading = () => {
	return (
    <SiteWrapper>
      <Page.Content title="Tiddlers Mapping">

        <div className="container">
          <div className="row ">

            <div className="col-12">
              <Card >

                <Card.Header>
                  <Card.Title>Tiddlers lists</Card.Title>
                </Card.Header>

                <Card.Body >

                  <div className="input-group mb-3">

                    <span className="input-group-text" id="basic-addon1">tiddlers 1</span>
					<span className="input-group-text" id="basic-addon1">tiddlers 2</span>
					<span className="input-group-text" id="basic-addon1">tiddlers 3</span>
					<span className="input-group-text" id="basic-addon1">tiddlers 4</span>
					<span className="input-group-text" id="basic-addon1">tiddlers 5</span>					


                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* <div dangerouslySetInnerHTML={content} ></div> */}
            <div className="col-12">
              <Card>

                <Card.Header>
                  <Card.Title>Tiddlers alignement</Card.Title>
                </Card.Header>

                <Card.Body>
                  <span> here goes alignment</span>
                </Card.Body>

              </Card>
            </div>

          </div>
        </div>

      </Page.Content>
    </SiteWrapper >
  )
}
export default OntologyLoading;