const DEFAULT_LANGUAGE = 'fr';

const resultsToGraphData = (queryResult) => {
  let edges = []
  let nodes = []
  console.log("Converting query results into graph data : ")
  console.log(queryResult)

  if(queryResult['@graph']){
    console.log("result is in json ld format ! ")

    queryResult['@graph'].forEach(node => {
      if(node['@id']){

        // Get the label of the node
        let label = node.label;
        if(Array.isArray(label)){
          const _label = node.label.find(l => l['@language'] === DEFAULT_LANGUAGE)
          label = _label['@value']
        }

        // Keep the found node
        nodes.push({
          id: node['@id'], label, 
          shape: label ? undefined : 'diamond' // If no label, this is an intermediate node
        })
        console.log("Found node", {id: node['@id'], label})

        // Create the node's edges
        Object.keys(node).forEach(edgeName => {
          const edge = queryResult['@context'][edgeName]
          console.log(label, " - Checking edge", edgeName, { edge })
          if(['label'].indexOf(edgeName) < 0 && edge){
            console.log('Attribute to keep found:', edgeName)
            console.log(edgeName, node[edgeName])

            const from = node['@id']
            const tos = [node[edgeName]].flat()
            tos.forEach(to => {
              if(typeof to === 'string'){
                edges.push({
                  from,
                  to,
                  label: edgeName,
                })
              }
            })
          }
        })
      }
    })
  }

  // Create missing nodes
  edges.forEach(({ to }) => {
    if(to && nodes.map(({ id }) => id).indexOf(to) < 0){
      console.warn('Creating missing node:', {id: to, label: to })
      nodes.push({id: to, label: to })
    }
  })

  return {edges, nodes: nodes}
}


/**
 * Formatte les données reçues de Fuseki en un format interprétable pour construire un tableau HTML
 * @param {object} queryResult 
 * @returns {Array[Object]} Retourne le résultat formatté
 */
const resultsToDataTable = (queryResult) => {

  /* Named variable in query
  * @example:
  *    SELECT ?subject ?a ?object
  *    FROM <http://example/Actions_Sanz>
  *    WHERE {
  *      ?s ?a ?o.
  *      ?s rdfs:label ?subject.
  *      ?o rdfs:label ?object
  *    }
  *    LIMIT 25
  */

  if(!queryResult){ return [] }
  const result = []
  if(queryResult && queryResult.results){
  queryResult.results.bindings.forEach((value) => {
    const row = {}
    Object.keys(value).forEach(k => {
      row[k] = value[k].value
    })
    result.push(row)
  })}
  console.log({ result })
  return result
}
export { resultsToDataTable, resultsToGraphData }