import React, { useCallback, useEffect, useRef, useState } from 'react';
import SiteWrapper from '../SiteWrapper';
import { Page, Card, Button } from 'tabler-react';
import axios from 'axios';
import { Link } from 'react-router-dom'

import DataTable from 'datatables.net';
import Iframe from 'react-iframe';
import graphExporter from '../lib/export_graphs';
import $ from 'jquery';
import getCookie from '../lib/get_cookie'
import MD5 from "crypto-js/md5";
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner'

$.DataTable = DataTable;

const csrftoken = getCookie('csrftoken');
axios.defaults.headers.common['X-CSRFToken'] = csrftoken;


const TiddlyWiki = ({ id }) => {
	const iframeId = `tiddly-map-frame`;

	const [wiki, setWiki] = useState();
  const [views, setViews] = useState([])
	const [loadingView, setLoadingView] = useState(-1);
  const [isConvertingClasses, setIsConvertingClasses] = useState(false)
  const [isExportingAllRDF, setIsExportingAllRDF] = useState(false)

	const datatable = useRef();

	useEffect(
		() => {
			if (id) {
				axios.get(`/api/tiddly_wiki/${id}.json`).then(({ data }) => setWiki(data));
			} else {
				axios.get(`/api/tiddly_wiki/first`).then(({ data }) => setWiki(data.data));
			}
		},
		[id]
	);

  const loadViews = useCallback(() => {
    wiki && wiki.id && axios.get(`/api/tiddly_wiki/${wiki.id}/tiddly_map_views`).then(({ data: { data } }) => setViews(data.map(view => ({...view.attributes, id: view.id}))));
  }, [wiki])

	useEffect(
		() => {
      if(wiki && wiki.id){ loadViews() }
		},
		[wiki, loadViews]
	);


	/**
	 * Write javascript code into iFrame
	 * @param {string} script The content of code
	 * @param {*} scriptId The id of the script, for caching purpose
	 */
	const putScriptInIframes = useCallback(
		(script, scriptId = new Date().getTime()) => {
			var $iframes = $(`#${iframeId}`);
			$iframes.each(function () {
				var thisDoc = this.contentWindow.document;
				if (!thisDoc.getElementById(scriptId)) {
					var scriptObj = thisDoc.createElement('script');
					scriptObj.type = 'text/javascript';
					scriptObj.id = scriptId;
					scriptObj.innerHTML = script;
					thisDoc.body.appendChild(scriptObj);
				}
			});
		},
		[iframeId]
	);

	/**
	 * Executes javascript code into iFrame
	 * @param {string} script The script to execute
	 */
	const executeInIFrame = useCallback(
		(script) => {
			const resultHolder = `result${new Date().getTime()}`;
			putScriptInIframes(`window.${resultHolder} = () => {${script}};`);
			putScriptInIframes(`window.${resultHolder}_result = window.${resultHolder}()`);
			var $iframes = $(`#${iframeId}`);
			let result;
			$iframes.each(function () {
				result = this.contentWindow[`${resultHolder}_result`];
			});
			// console.log({ script, result, iframeId, j: $(`#${iframeId}`) });
			return result;
		},
		[putScriptInIframes, iframeId]
	);

	/**
	 * Display TiddlyMap as full screen
	 */
	const toFullScreen = useCallback(
		() => {
			const tiddlyMapReady = executeInIFrame(
				`return document.getElementsByClassName('tmap-fullscreen-button').length > 0`
			);
			if (tiddlyMapReady) {
				executeInIFrame(`document.getElementsByClassName('tmap-fullscreen-button')[0].click();`);
			} else {
				setTimeout(toFullScreen, 3000);
			}
		},
		[executeInIFrame]
	);

	window.executeInIFrame = executeInIFrame;

	/**
	 * Changes the displayed TiddlyMap view
	 * @param {object} view The view to display
	 */
	const displayView = useCallback(
		(view) => executeInIFrame(`$tm.utils.setText($tm.ref.defaultViewHolder, '${view.name}')`),
		[executeInIFrame]
	);

	/**
	 * Copy export script into iFrame
	 */
	const copyGraphExporterScript = useCallback(
		() => putScriptInIframes(`window.exportGraphs = ${graphExporter}`, 'exportGraph'),
		[putScriptInIframes]
	);

	useEffect(
		() => {
			toFullScreen();
			copyGraphExporterScript();
		},
		[wiki, toFullScreen, copyGraphExporterScript]
	);

	/**
	 * exports all views
	 */
	const saveAll = () => {
		putScriptInIframes(
			`exportGraphs(${JSON.stringify({ views: wiki.attributes.tiddly_map_views.map(({ name }) => name), download: true })})`
		);
	};

	/**
	 * Exports the given view
	 * @param {object} graph 
	 */
	const exportGraph = (graph) => {
		putScriptInIframes(`exportGraphs(${JSON.stringify({ views: [graph.name], download: true })})`);
	};

	/**
	 * Get the content of the view export
	 *  
	 * @param {object} graph 
	 */
	const readGraph = (graph, tries = 15) => {
		let g = executeInIFrame(`return exportGraphs(${JSON.stringify({ views: [graph.name], download: false })})`);
		console.log(g)
		if (g) { return Promise.resolve(g) }
		if (tries === 1) { return Promise.reject(`Graph ${graph} could not be read`) }
		console.warn(`Retry to get graph "${graph.name}" (${tries - 1} tries left)`)
		return new Promise((resolve, reject) => setTimeout(() => readGraph(graph, tries - 1)
			.then(resolve)
			.catch(reject), 3000))
	};

	/**
	 * Converts and export TiddlyMap view to the RDF store
	 * 
	 * @param {object} graph 
	 * @param {Promise} promiseChain 
	 */
	const toRDF = (graph, promiseChain = Promise.resolve()) => { // Looks like this only works when graph is displayed
		setLoadingView(graph.id)
		return readGraph(graph)
    .then(g => {
			try {
				if (!g) { throw new Error(`Graph ${graph} could not be read`) }
				const { edges, nodes } = g[0];
				const json_content = {
					edges: Object.values(edges),
					nodes: Object.values(nodes)
				}
				return promiseChain.then(() =>
					axios.post(`/api/tiddly_map_exports/rdf`, {
						data: {
							attributes: {
								json_content,
								content_hash: MD5(JSON.stringify(json_content)).toString(),
								tiddly_map_view_id: graph.id,
							},
							type: 'TiddlyMapExport'
						},
					}, {
						headers: { 'Content-Type': 'application/vnd.api+json' }
					})
						.then(() => {
							toast.success(`Graph ${graph.name} exporté`)
							setLoadingView(-1)
              loadViews()
						})
						.catch(({ response: { data: errors } }) => {
							console.log(errors)
							toast.error(`Échec de l'importation du graph ${graph.name}: ${(errors?.errors || []).map(e => console.log(e) || e.detail)}`)
							setLoadingView(-1)
						})
				);
			} catch (e) {
				console.error(e);
				toast.error(`Échec de l'importation du graph ${graph.name}: ${e.message}`)
				setLoadingView(-1)
			}
		})
    .catch(error => toast.error(error.message))
	};


	const toRDFFromTag = (graph, promiseChain = Promise.resolve()) => { // Looks like this only works when graph is displayed
		setLoadingView(graph.id)
		return readGraph(graph)
    .then(g => {
			try {
				if (!g) { throw new Error(`Graph ${graph} could not be read`) }
				const { edges, nodes } = g[0];
				const json_content = {
					edges: Object.values(edges),
					nodes: Object.values(nodes)
				}
				return promiseChain.then(() =>
					axios.post(`api/tiddly_map_exports/rdf_from_tags`, {
					//axios.post(`api/tiddly_map_exports/rdf`, {
						data: {
							attributes: {
								json_content,
								content_hash: MD5(JSON.stringify(json_content)).toString(),
								tiddly_map_view_id: graph.id,
							},
							type: 'TiddlyMapExport'
						},
					}, {
						headers: { 'Content-Type': 'application/vnd.api+json' }
					})
						.then(() => {
							toast.success(`Graph ${graph.name} exporté par tags`)
							setLoadingView(-1)
              loadViews()
						})
						.catch(({ response: { data: errors } }) => {
							console.log(errors)
							toast.error(`Échec de l'importation à partir des tags du graph ${graph.name}: ${(errors?.errors || []).map(e => console.log(e) || e.detail)}`)
							setLoadingView(-1)
						})
				);
			} catch (e) {
				console.error(e);
				toast.error(`Échec de l'importation à partir des tags du graph ${graph.name}: ${e.message}`)
				setLoadingView(-1)
			}
		})
    .catch(error => toast.error(error.message))
	};

	/**
	 * Exports all TiddlyMap views into the RDF store
	 */
	const exportAllViews = () => {
		setIsExportingAllRDF(true);

		let promiseChain = Promise.resolve();

		return Promise.all(wiki.attributes.tiddly_map_views.map((view) => (
			toRDFFromTag(view, promiseChain)
		)))
    .then(() => setIsExportingAllRDF(false));
	};

  const rdfQuery = (query) => {
		const params = new URLSearchParams();
		params.append('query', query);
		return axios.post('/fuseki/ds/sparql', params, {
			headers: {
				Accept: 'application/json'
			}
		})
  }

  /**
   * Get the localized label of all the classes
   * 
   * @returns {object} A map of className / localized labels
   * @example
   * 
   *  getLocalizedClasses('fr')
   * //  {
   * //    https://data.april.fr/def/april#ARS: "ARS"
   * //    https://data.april.fr/def/april#Absence: "Absence"
   * //    https://data.april.fr/def/april#AbsoluteValueNumber: "Nombre Valeur Absolue",
   * // [...]
   * //  }
   */
  const getLocalizedClasses = (locale = 'fr') => {
    const query = `PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    
    SELECT DISTINCT ?class ?label
    WHERE {
      GRAPH ?g {
        ?class a ?type.
        ?class rdfs:label ?label.
        FILTER(LANG(?label) = "${locale}")
      }
    }`
    return rdfQuery(query).then(({ data }) => data.results.bindings.reduce((
      prev, {
			class: { value: className },
			label: { value: label },
      }
		) => {
      prev[className] = label;
      return prev;
    }, {}));
  }

  /**
   * Ajoute à chaque tiddler un tag par classe RDF définissant l'instance
   * @returns {Promise}
   */
	const rdfInstanceClassesToTags = () => {
		const query = `PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    
    SELECT DISTINCT ?subject ?title ?object
    WHERE {
        GRAPH ?g {
          ?subject rdf:type ?object .
          ?subject rdfs:label ?title . 
      }
    }`
		return getLocalizedClasses()
    .then((localizedClasses) => (
      console.log(localizedClasses) || 
      rdfQuery(query).then(({ data }) => data.results.bindings.map(({
        subject: { value: instanceURI },
        object: { value: typeURI },
        title: { value: label }
      }) => ({
        id: instanceURI.split('/id/')[1],
        className: typeURI,
        label
      })))
        .then(instanceClasses => instanceClasses.reduce((prev, cur) => {
          if (!prev[cur.id]) { prev[cur.id] = { id: cur.id, tags: [], title: cur.label } }
          prev[cur.id].tags.push(localizedClasses[cur.className])
          return prev
        }, {}))
        .then(instanceClasses => setTiddlMapTags(Object.values(instanceClasses)))
      )
    )
	}

  /**
   * Enregistre les tags dans TiddlyWiki selon les noms de tiddlers et leur(s) classe(s) 
   * fournis en paramètre
   * 
   * @param {object[]} rdfClasses Les objets représentant les classes de chaque tiddler 
   * @returns {Promise} La promesse
   */
	const setTiddlMapTags = (rdfClasses) => {
    console.log({ rdfClasses, tiddlers: rdfClasses.map(({ title, tags}) => {
      const res ={ title, tags}
      return res;
    }) })
    const s = `
    const tiddlersData = JSON.parse(\`${JSON.stringify(
      rdfClasses.map(({ title, tags}) => {
        const res ={ title, tags}
        return res;
      })
    )}\`)
    const newTiddlersData = []
    tiddlersData.forEach(({ title, tags }) => {
      const tiddler = window.$tw.wiki.getTiddler(title)
      if(tiddler){
        newTiddlersData.push({ ...tiddler.fields, tags })
      } else {
        newTiddlersData.push({ title, tags })
      }
    })
    const res = window.$tw.wiki.addTiddlers(newTiddlersData)
    return res
  `;

    return executeInIFrame(s)
	}

  /**
   * Obtient les classes selon l'ontologie téléversée et les convertit
   * en tag TiddlyWiki. Au besoin, créé les Tiddlers représentant un tag
   * si il n'existe pas.
   * 
   * @returns {Promise} La promesse
   */
  const rdfClassesToTags = () => axios.get(`/api/parsed_ontology_classes`)
    .then(({ data: { classes }}) => {
      const h = reverseClassHierarchy(classes)
      const tiddlersClasses = Object.keys(h).map(childName => {
        return {
          title: childName,
          tags: h[childName]
        }
      })
      console.log({ h, tiddlersClasses })
      return setTiddlMapTags(tiddlersClasses)
    })

  /**
   * Renverse la hierarchie fournie par l'API.
   * 
   * @example
   * 
   *    const classes = {
   *      name: "Kind Of Document",
   *      children: [{ 
   *        name: "Information Nature", 
   *        children: [{
   *          name: "Answer", 
   *          children: []
   *        }]
   *      }]
   *    }
   * 
   *    reverseClassHierarchy(classes)
   *    // Résultat: {
   *    //   "Answer": ["Information Nature"],
   *    //   "Information Nature": ["Kind Of Document"]
   *    // }
   * @param {object[]} classes Les classes parentes 
   * @param {object} hierarchy La hierarchie inversée
   * @returns {object} La hiérarchie inversée finale
   */
  const reverseClassHierarchy = (classes, hierarchy = {}) => {
    
    classes.forEach( ({ name: parentName, children }) => {
      // Réinitialisation de la classe parente au besoin
      hierarchy[parentName] = hierarchy[parentName] || []

      // Inversion parent/enfant
      children.forEach(child => {
        hierarchy[child.name] = hierarchy[child.name] || []
        hierarchy[child.name].push(parentName)
      })

      // Récurcivité
      reverseClassHierarchy(children, hierarchy)
    })
    return hierarchy;
  }

  /**
   * Convertit les classes RDF trouvées dans Fuseki en tags TiddlyWiki
   * @returns {Promise} La promesse
   */
	const rdfClassesToTiddlyMapTag = () => {
    setIsConvertingClasses(true);
    return Promise.all([
      rdfInstanceClassesToTags(),
      rdfClassesToTags(),
    ])
    .then(() => toast.success(`Conversion des classes RDF en tags terminée`))
    .catch((error) => toast.error(
      `Échec de la conversion des classes RDF en tags: ${error.message}`)
    )
    .then(() => setIsConvertingClasses(false))
  }
	return (
		<SiteWrapper>
      		<Page.Content title="ExG">
				<Card>
					<Card.Body>
						<React.Fragment>
							<Button className="btn btn-primary m-2" onClick={saveAll}>Tout exporter (JSON)</Button>
							<Button className="btn btn-primary m-2" disabled={isExportingAllRDF} onClick={exportAllViews}>
              Tout exporter (RDF)
              {
									isExportingAllRDF  && <Loader
											type="Puff"
											color="#000"
											height={15}
											width={15} //3 secs
										/>
								}
							</Button>
							<Button className="btn btn-primary m-2" disabled={isConvertingClasses} onClick={rdfClassesToTiddlyMapTag}>
                Classes RDF -&gt; Tag TiddlyMap
                {isConvertingClasses  && <Loader
											type="Puff"
											color="#000"
											height={15}
											width={15} //3 secs
										/>}</Button>

							{wiki && <Iframe
								url={wiki.attributes.wiki_link}
								width="100%"
								height="800px"
								id={iframeId}
								className="myClassname"
								display="initial"
								position="relative"
								allowFullScreen
								onLoad={(a, b, c) => console.log({ a, b, c })}
							/>}

							<table
								ref={datatable}
								className="table table-bordered"
								id="dataTable"
								width="100%"
								cellSpacing="0"
							>
								<thead>
									<tr>
										<th>Nom</th>
										<th>Créé le</th>
									</tr>
								</thead>
								<tbody>
									{views &&
										views.map((view) => (
											<tr key={`view-${view.id}`} className={view.last_export ? (view.last_export.obsolete ? 'table-warning': '') : 'table-danger'}>
												<td onClick={() => displayView(view)}>{view.name}</td>
												<td>{view.created_at}</td>
												<td>
													<Button
														onClick={() => displayView(view)}
														className="mx-1 btn-sm btn btn-primary"
													>
														Visualiser
													</Button>

													<Button
														onClick={() => exportGraph(view)}
														className="mx-1 btn-sm btn btn-primary"
													>
														Télécharger
													</Button>
													<button
														type="button"
														onClick={() => toRDFFromTag(view)}
														className="mx-1 btn-sm btn btn-success"
													>
														{
															loadingView !== view.id ?
																"TAGS to RDF"
																:
																<Loader
																	type="Circles"
																	color="#fff"
																	height={15}
																	width={15} //3 secs
																/>
														}
													</button>	

                          <Link
                            to={{ pathname: `/tiddly_map_views/${view.id}/exports`, state: { view } }}
                            className="mx-1 btn-sm btn btn-primary" >
                            Exportations
                          </Link>

                          <Link
                            to={{ pathname: `/tiddly_map_exports/${view.last_export && view.last_export.id}`, state: { tiddlyMapExport: view.last_export } }}
                            className={`mx-1 btn-sm btn ${view.last_export && view.last_export.obsolete ? 'btn-warning' : 'btn-primary'}`} 
                            disabled={!view.last_export}
                            >
                            Dernière exportation
                          </Link>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</React.Fragment>
					</Card.Body>
				</Card>
			</Page.Content>
		</SiteWrapper>
	);
};

export default TiddlyWiki;
