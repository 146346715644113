import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';       // add this
import './index.css';
import '@neos21/bootstrap3-glyphicons/dist/css/bootstrap3-glyphicons.min.css'
import reportWebVitals from './reportWebVitals';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
reportWebVitals(console.log);
