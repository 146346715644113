import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types'
import { Network } from 'vis-network'

const Graph = ({ nodes, edges }) => {
  // ref for fom access
  const domReference = useRef(null)
  useEffect(() => {
    // use vis with created ref
    if (edges.length && nodes.length) {
      const network =
        domReference.current &&
        new Network(domReference.current,
          {
            nodes: nodes.filter((n, i, a) => a.indexOf(n) === i),
            edges: edges.filter((e, i, a) => a.indexOf(e) === i)
              .map(edge => ({
                ...edge,
                arrows: {
                  to: {
                    enabled: true,
                  },
                },
              }))
          },
          {
            physics: {
              enabled: true,
              repulsion: {
                  centralGravity: 0.0,
                  springLength: 100,
                  springConstant: 0.01,
                  nodeDistance: 100,
                  damping: 0.09
              },
              solver: 'repulsion'
          },
            autoResize: true,
            edges: {
              color: "#411811",
            },
          })

       network?.on("selectNode", (event) => {
         if (event.nodes?.length === 1) {
           console.log(event.nodes)
      //     window.location.href = event.nodes[0];
         }
      })

      return () => {
        if (network) {
          network.destroy()
        }
      }
    }


  }, [domReference, nodes, edges])

  const approxLinesOfText = Math.ceil(
    nodes.reduce((prev, curr) => prev + (curr.label?.length || 0), 0) / 14
  );

  const height = 2 * (approxLinesOfText + edges.length);

  return (
    <div ref={domReference}
      style={{
        height: `${height < 150 ? 150 : height}em`,
        width: `${8 * nodes.length + 8}em`,
        maxWidth: "100%",
        minWidth: "30%",
        maxHeight: "768px",
        marginLeft: 'auto',
        marginRight: 'auto',
        display: [nodes, edges].every(e => e && e.length) ? 'inherit' : 'none'
      }} />
  )

}

Graph.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  edges: PropTypes.arrayOf(PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired
}

Graph.defaultProps = {
  nodes: [],
  edges: []
}

export default Graph
