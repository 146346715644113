import axios from 'axios';
import React, { useState } from 'react'
import { Page, Card } from 'tabler-react';
import SiteWrapper from '../SiteWrapper';
import { toast } from 'react-toastify';

const OntologyLoading = () => {
  const [excelClassesFile, setExcelClassesFile] = useState('')
  const [owlClassesFile, setOwlClassesFile] = useState('')

  const uploadExcelFile = () => {
    if (excelClassesFile !== '') {
      var data = new FormData()
      data.append('file', excelClassesFile)
      axios.post('/api/excel_classes', data)
        .then((result) => {
          toast.success('Fichier mis a jour avec succès !')
        })
        .catch((error) => {
          toast.error("Une erreur s'est produite.")
          console.log(error)
        })
    } else {
      toast.error("Aucun fichier selectionné.")
    }

  }

  const loadExcelFile = (file) => {
    setExcelClassesFile(file)
  }

  const uploadOwlFile = () => {
    if (owlClassesFile !== '') {
      var data = new FormData()
      data.append('file', owlClassesFile)
      axios.post('/api/owl_classes', data)
        .then((result) => {
          toast.success('Fichier mis a jour avec succès !')
        })
        .catch((error) => {
          toast.error("Une erreur s'est produite.")
          console.log(error)
        })
    } else {
      toast.error("Aucun fichier selectionné.")

    }


  }

  const loadOwlFile = (file) => {
    setOwlClassesFile(file)
  }



  return (
    <SiteWrapper>
      <Page.Content title="Requete Sparql">
        <div className="container">
          <div className="row ">

            <div className="col">
              <Card >

                <Card.Header>
                  <Card.Title>Ontologie classes (OWL)</Card.Title>
                </Card.Header>

                <Card.Body >
                  <input type="file" accept=".owl" name="owlfile" id="owlfile" onChange={e => loadOwlFile(e.target.files[0])} />

                </Card.Body>
                <Card.Footer>
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={uploadOwlFile}>
                    Téléverser
                  </button>
                  <a className="float-right" href='/api/ontology/download/ontology'>
                    Télécharger la dernière version
                  </a>
                </Card.Footer>

              </Card>
            </div>

            {/* <div dangerouslySetInnerHTML={content} ></div> */}
            <div className="col">
              <Card style={{ maxWidth: "600px" }}>

                <Card.Header>
                  <Card.Title>Instances et leurs classes (Excel)</Card.Title>
                </Card.Header>
                <Card.Body>
                  <input type="file" accept=".xls,.xlsx" onChange={e => loadExcelFile(e.target.files[0])} />
                </Card.Body>
                <Card.Footer>
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={uploadExcelFile}>
                    Téléverser
                  </button>
                  <a className="float-right" href='/api/ontology/download/matrix'>
                    Télécharger la dernière version
                  </a>
                </Card.Footer>

              </Card>
            </div>

          </div>
        </div>

      </Page.Content>
    </SiteWrapper >
  )
}

export default OntologyLoading;