import Graph from "../components/Sparql/Graph"
import SiteWrapper from '../SiteWrapper';
import { Page, Card } from 'tabler-react';
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { resultsToGraphData, resultsToDataTable } from "../utils/queryResultExtractor"
import CodeMirror from '@uiw/react-codemirror';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/monokai.css';
import ReactDatatable from '@ashvin27/react-datatable';
import { toast } from 'react-toastify';
import Scroll from 'react-scroll';
const Element  = Scroll.Element;
const scroller = Scroll.scroller;

const SparqlRequest = () => {

  const [query, setQuery] = useState(localStorage.getItem("last_request"))

  const [queriesSnippets, setQueriesSnippets] = useState([])
  const [selectedQuerrySnippet, setSelectedQuerySnippet] = useState("")

  const [isRequestConstruct, setisRequestConstruct] = useState(false)

  const [queryResultGraph, setQueryResultGraph] = useState([])
  const [queryResultData, setQueryResultData] = useState([])

  const [queryName, setQueryName] = useState("")

  useEffect(() => {
    fetchQueries()
  }, [])

  /**
   * Obtient les 25 dernières requêtes enregistrées
   */
  const fetchQueries = () => {
    axios.get(`/api/queries/25/recent`)
      .then(({ data: { data } }) => setQueriesSnippets(data))
  }

  /**
   * Exécute la requête écrite
   */
  const execRequest = () => {
    const params = new URLSearchParams();
    params.append('query', query);
    axios.post('/fuseki/ds/sparql', params, {
      headers: { 
        Accept: isRequestConstruct ? 'application/ld+json' : 'application/json' 
      } 
    }).then(({ data }) => {
      if(isRequestConstruct){
        // Draw graph
        setQueryResultGraph(
          resultsToGraphData(data)
        )
        setQueryResultData([])
      } else {
        // Fill data table
        setQueryResultData(resultsToDataTable(data))
        setQueryResultGraph([])
      }
      // Somewhere else, even another file
      scroller.scrollTo('result', {
        duration: 500,
        delay: 100,
        smooth: true,
        offset: 50, // Scrolls to element + 50 pixels down the page
      })
    })
    .catch(err => {
      console.error(err)
      switch(err.response?.status) {
        case 400:
          toast.error("Erreur dans la synthaxe sparql.")
          break;
        default:
          toast.error(err.statusText)
      }

    })
  }

  useEffect(() => {
    localStorage.setItem("last_request", query)
    setisRequestConstruct(query.includes('CONSTRUCT '))
  }, [query])

  const saveQuery = () => {
    const formData = new FormData();

    formData.append('query_name', queryName);
    formData.append('query_string', query);
    
    axios.post(`/api/queries/`, formData)
      .then(() => fetchQueries())
  }

  const loadQuerySnippet = () => {
    if(selectedQuerrySnippet.attributes){
      setQuery(selectedQuerrySnippet.attributes.query_string)
    }
  }

  const adaptQuery = (queryString) => {
    if (queryString.slice(-1) !== '\n') {
      return queryString + '\n'
    }

    return queryString
  }

  const updateQuery = (editor) => {
    setQuery(adaptQuery(editor.getValue()))
  }

  return (
    <SiteWrapper>
      <Page.Content title="Requete Sparql">

        <div className="container">
          <div className="row ">

            <div className="col-12">
              <Card >

                <Card.Header>
                  <Card.Title>Requete Sparql</Card.Title>
                </Card.Header>

                <Card.Body >

                  <div className="input-group mb-3">

                    <span className="input-group-text" id="basic-addon1">Enregistrer la requete</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nom"
                      aria-label="Nom"
                      aria-describedby="basic-addon1"
                      onChange={e => setQueryName(e.target.value)} />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => { saveQuery() }}>
                      Confirmer
                    </button>
                  </div>

                  <div className="input-group mb-3">
                    <label className="input-group-text"> Charger une requête </label>
                    <select
                      className="form-select-lg form-control"
                      id="inputGroupSelect01"
                      aria-label="Default select example"
                      onChange={e => { setSelectedQuerySnippet(queriesSnippets[e.target.value]) }}
                    >
                      {
                        (queriesSnippets || []).map((query, index) => {
                          return (
                            <option value={index} key={index}>
                              {query.attributes.query_name}
                            </option>
                          )
                        })
                      }
                    </select>
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => loadQuerySnippet()}>
                      Charger
                    </button>
                  </div>


                  <CodeMirror
                    value={query}
                    options={{
                      mode: 'sparql',
                      indentWithTabs: true,
                      viewportMargin: 1,
                    }}
                    aria-label="Requete Sparql"
                    onChange={editor => updateQuery(editor)}

                  />

                  <div className="col-12 text-right">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={execRequest}>
                      Executer
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* <div dangerouslySetInnerHTML={content} ></div> */}
            <div className="col-12">
              <Element name="result" className="element">
              <Card>

                <Card.Header>
                  <Card.Title>Résultat</Card.Title>
                </Card.Header>

                <Card.Body>
                  <div className={queryResultGraph && queryResultGraph.nodes && queryResultGraph.edges ? undefined : 'd-none'}>
                  <Graph
                      {...queryResultGraph}
                    />
                    </div>
                  <div className={queryResultData && queryResultData.length ? undefined : 'd-none'}>
                      <ReactDatatable
                      config={{
                        page_size: 100,
                        length_menu: [ 100, 500, 1000 ],
                        button: {
                          print: true,
                          csv: true,
                        }}
                      }
                      records={queryResultData}
                      columns={queryResultData && queryResultData.length
                        ? Object.keys(queryResultData[0]).map((columnName) => ({
                            key: columnName,
                            text: columnName,
                            align: "left",
                            sortable: true,
                          }))
                        : []}
                  />
                  </div>
                </Card.Body>

              </Card>
              </Element>
            </div>

          </div>
        </div>

      </Page.Content>
    </SiteWrapper >
  )
}

export default SparqlRequest;
