import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "tabler-react/dist/Tabler.css";
import './App.css'
import TiddlyWiki from './pages/TiddlyWiki'
import TiddlyMapView from './pages/TiddlyMapView'
import TiddlyMapExportList from './pages/TiddlyMapExport/index'
import TiddlyMapExportShow from './pages/TiddlyMapExport/show'
import SparqlRequest from './pages/SparqlRequest'
import OntologyLoading from './pages/OntologyLoading'
import TiddlersMapping from './pages/TiddlersMapping'

const Error404 = () => <h1>404 PAGE</h1>

const App = () => {
  return (
    <React.StrictMode>
      <Router>
        <Switch>
          <Route exact path="/" component={TiddlyWiki} />
          <Route exact path="/tiddly_wikis" component={TiddlyWiki} />
          <Route exact path="/tiddly_map_views" component={TiddlyMapView} />
          <Route exact path="/tiddly_map_views/:id/exports" component={TiddlyMapExportList}/>
          <Route exact path="/tiddly_map_exports/:id" component={TiddlyMapExportShow}/>
          <Route exact path="/sparql_request" component={SparqlRequest}/>
          <Route exact path="/ontology" component={OntologyLoading}/>
		  <Route exact path="/tiddlers" component={TiddlersMapping}/>
          {/* <Route exact path="/400" component={Error400} /> */}
          {/* <Route exact path="/401" component={Error401} />
          <Route exact path="/403" component={Error403} />
          <Route exact path="/404" component={Error404} />
          <Route exact path="/500" component={Error500} />
          <Route exact path="/503" component={Error503} />
          <Route exact path="/blog" component={BlogPage} />
          <Route exact path="/cards" component={CardsDesignPage} />
          <Route exact path="/charts" component={ChartsPage} />
          <Route exact path="/email" component={Email} />
          <Route exact path="/empty-page" component={Empty} />
          <Route exact path="/form-elements" component={FormElementsPage} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route exact path="/gallery" component={GalleryPage} />
          <Route exact path="/icons" component={IconPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/maps" component={MapCardsPage} />
          <Route exact path="/pricing-cards" component={PricingCardsPage} />
          <Route exact path="/profile" component={ProfilePage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/store" component={StoreCardsPage} /> */}
          <Route component={Error404} />
        </Switch>
      </Router>
    </React.StrictMode>
  );
}

export default App;