// @flow

import * as React from "react";
import { NavLink, withRouter } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Site,
  RouterContextProvider,
} from "tabler-react";


const navBarItems = [
  {
    value: "Administration",
    to: "/admin",
    icon: "home",
    useExact: true,
  },
  {
    value: "API",
    to: '/api',
    icon: "box",
    useExact: true,
  },
  {
    value: "TiddlyWiki",
    icon: "git-merge",
    to: "/tiddly_wikis", 
    prefix: "fa",
    LinkComponent: withRouter(NavLink)  
  },
  {
    value: "Constructeur de requête",
    icon: "git-merge",
    to: "/sparql_request", 
    prefix: "fa",
    LinkComponent: withRouter(NavLink)  
  },
  {
    value: "Ontologie",
    icon: "upload",
    to: "/ontology", 
    prefix: "fa",
    LinkComponent: withRouter(NavLink)  
  },
  // {
  //   value: "Fuseki",
  //   icon: "database",
  //   to: "/fuseki/", 
  //   prefix: "fa",
  //   LinkComponent: withRouter(NavLink)  
  // },
   {
    value: "Tiddlers",
    icon: "git-merge",
    to: "/tiddlers", 
    prefix: "fa",
    LinkComponent: withRouter(NavLink)  
  },
  // {
  //   value: "Pages",
  //   icon: "file",
  //   subItems: [
  //     { value: "Profile", to: "/profile", LinkComponent: withRouter(NavLink) },
  //     { value: "Login", to: "/login", LinkComponent: withRouter(NavLink) },
  //     {
  //       value: "Register",
  //       to: "/register",
  //       LinkComponent: withRouter(NavLink),
  //     },
  //     {
  //       value: "Forgot password",
  //       to: "/forgot-password",
  //       LinkComponent: withRouter(NavLink),
  //     },
  //     { value: "400 error", to: "/400", LinkComponent: withRouter(NavLink) },
  //     { value: "401 error", to: "/401", LinkComponent: withRouter(NavLink) },
  //     { value: "403 error", to: "/403", LinkComponent: withRouter(NavLink) },
  //     { value: "404 error", to: "/404", LinkComponent: withRouter(NavLink) },
  //     { value: "500 error", to: "/500", LinkComponent: withRouter(NavLink) },
  //     { value: "503 error", to: "/503", LinkComponent: withRouter(NavLink) },
  //     { value: "Email", to: "/email", LinkComponent: withRouter(NavLink) },
  //     {
  //       value: "Empty page",
  //       to: "/empty-page",
  //       LinkComponent: withRouter(NavLink),
  //     },
  //     { value: "RTL", to: "/rtl", LinkComponent: withRouter(NavLink) },
  //   ],
  // },
  // {
  //   value: "Forms",
  //   to: "/form-elements",
  //   icon: "check-square",
  //   LinkComponent: withRouter(NavLink),
  // },
  // {
  //   value: "Gallery",
  //   to: "/gallery",
  //   icon: "image",
  //   LinkComponent: withRouter(NavLink),
  // },
  // {
  //   icon: "file-text",
  //   value: "Documentation",
  //   to:
  //     process.env.NODE_ENV === "production"
  //       ? "https://tabler.github.io/tabler-react/documentation"
  //       : "/documentation",
  // },
];

class SiteWrapper extends React.Component {
  render() {
    return (
      <Site.Wrapper
        headerProps={{
          href: '/',
          imageURL: '/Logo_BRGM.svg'
        }}
      >
        <ToastContainer />
        {this.props.children}
      </Site.Wrapper>
    );
  }
}

export default SiteWrapper;
