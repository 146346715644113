const exportGraphs = ({ views = [], download = true }) => {
  // Project APRIL
  // Export all the tiddly Views (graphs) as a json file
  // Maybe, not use tiddly chrome extension, open the HTML file directly with Chrome
  // Open the console (F12 key), copy and past this js code in console and press enter
  // accept to save json
  // https://github.com/felixhayashi/TW5-TiddlyMap/blob/81322038e4ecc107dc6af2e38e6cfde567fca7e9/src/plugins/felixhayashi/tiddlymap/js/services/Listener.js#L98

  // https://github.com/felixhayashi/TW5-TiddlyMap/blob/81322038e4ecc107dc6af2e38e6cfde567fca7e9/src/plugins/felixhayashi/tiddlymap/js/lib/utils/wiki.js#L106
  function setField(tiddler, field, value) {
    const tRef = getTiddlerRef(tiddler);
    const fields = { title: tRef };
    fields[field] = value

    // do not use any tObj provided, it may result in a lost update!
    let tObj = window.$tw.wiki.getTiddler(tRef, true);

    if (field !== 'text' && tObj && !tObj.fields.text) {
      fields.text = '';
    }

    tObj = new window.$tw.Tiddler(tObj, fields);
    window.$tw.wiki.addTiddler(tObj);

    return tObj;
  }

  // https://github.com/felixhayashi/TW5-TiddlyMap/blob/81322038e4ecc107dc6af2e38e6cfde567fca7e9/src/plugins/felixhayashi/tiddlymap/js/lib/utils/tmap.js#L58
  function getValues(col) {
    if (Array.isArray(col)) {
      return col; // bounce back.
    }

    // else if (col instanceof vis.DataSet) { // a dataset

    //   return col.get({ returnType: 'Array' });

    // }

    const result = [];
    const keys = Object.keys(col);
    keys.forEach(key => {
      result.push(col[key]);
    })

    return result;
  };

  //https://github.com/felixhayashi/TW5-TiddlyMap/blob/81322038e4ecc107dc6af2e38e6cfde567fca7e9/src/plugins/felixhayashi/tiddlymap/js/lib/utils/tmap.js#L92
  function convert(col, outputType) {
    if (typeof col !== 'object') {
      //throw new InvalidArgumentException(col, outputType);
    }

    if (outputType === 'object') {
      outputType = 'hashmap';
    }

    const mapper = {
      array: col => getValues(col),
      //   hashmap: col =>
      //     col instanceof vis.DataSet ? col.get({ returnType: 'Object' }) : col,
      //   dataset: col =>
      //     col instanceof vis.DataSet ? col : (!Array.isArray(col) ? getValues(col) : new vis.DataSet(col))
    };

    return mapper[outputType](col);

  };

  // https://github.com/felixhayashi/TW5-TiddlyMap/blob/81322038e4ecc107dc6af2e38e6cfde567fca7e9/src/plugins/felixhayashi/tiddlymap/js/lib/utils/wiki.js#L27
  function getTiddlerRef(tiddler) {
    if (tiddler instanceof window.$tw.Tiddler) {
      return tiddler.fields.title;
    } else if (typeof tiddler === 'string') {
      return tiddler;
    } else {

    }
  };

  function getTags(wiki, nodes) {
    const EXCLUDED_TAGS = [
      "bag",
      "created",
      "modified",
      "revision",
      "title",
      // "tmap.fa-icon",
      "tmap.id",
      "tmap.style",
      "tmap.edges",
      "type"
    ]

    const node_ids = Object.keys(nodes);

    wiki.each((tiddly) => {
      if (node_ids.includes(tiddly.fields["tmap.id"])) {
        Object.keys(tiddly.fields)
          .filter(field => {
            return !EXCLUDED_TAGS.includes(field)
          })
          .forEach(key => {
            nodes[tiddly.fields["tmap.id"]][key] = tiddly.fields[key]
          })
      }
    })
  }


  // list all the views in the select
  if (!views.length) {
    console.log(document.getElementsByTagName("select"))
    var selectViewsEl = document.getElementsByTagName("select")[0];
    console.log({ selectViewsEl })
    console.log({ opt: selectViewsEl.options })
    console.log({ fae: selectViewsEl.options.forEach })
    for (var i = 0; i < selectViewsEl.options.length; i++) {
      views.push(selectViewsEl.options[i].value); //second console output
    }
  }

  console.log("Nb views: " + views.length);

  // export graph
  if (!download) {
    return views.map(view => {

      var graph = window.$tm.adapter.getGraph({ view });
      getTags(window.$tm.adapter.wiki, graph.nodes);
      console.log(graph)
      return graph;
    })
  }
  return views.forEach(view => {
    var graph = window.$tm.adapter.getGraph({ view });
    if (graph) {
      graph.nodes = convert(graph.nodes, 'array');
      graph.edges = convert(graph.edges, 'array');
      console.log({ download, views, graph })
      if (!download) { return console.log({ graph }) || { view, graph }; }
      const tRef = '$:/temp/tmap/export';

      setField(tRef, 'text', JSON.stringify(graph, null, 2));

      window.$tw.rootWidget.dispatchEvent({
        type: 'tm-download-file',
        param: tRef,
        paramObject: {
          filename: `${view}.json`
        }
      });
    } else {
      console.log("Fail to extract graph for view: " + view)
    }
  })
}

export default exportGraphs
