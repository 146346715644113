import axios from "axios"
import { useEffect, useState } from "react"
import SiteWrapper from '../../SiteWrapper';
import { Link } from 'react-router-dom'
import { Page, Card } from 'tabler-react';
import {  Button } from 'tabler-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const TiddlyMapExport = ({ match: { params: { id: tiddlyMapViewId } } }) => {
  const [tiddlyMapExports, setTiddlyMapExports] = useState([])
  const [tiddlyMapView, setTiddlyMapView] = useState()
  const [currentExport, setCurrentExport ] = useState()
  useEffect(() => {
    axios.get(`/api/tiddly_map_views/${tiddlyMapViewId}/exports`)
      .then(({ data: { data } }) => setTiddlyMapExports(data))
    axios.get(`/api/tiddly_map_views/${tiddlyMapViewId}`)
      .then(({ data: { data } }) => setTiddlyMapView(data))
  }, [tiddlyMapViewId])

  useEffect(() => {
    if(tiddlyMapExports.length && !currentExport){
      setCurrentExport(tiddlyMapExports[tiddlyMapExports.length - 1])
    }
  }, [tiddlyMapExports, currentExport])

  useEffect(() => {
    if(currentExport){
      axios.get(`/api/tiddly_map_exports/${currentExport.id}`)
    }
  }, [currentExport])

  return (
    <SiteWrapper>
      <Page.Content title="ExG">
        <Card>
          <Card.Body>
            <table
              className="table table-bordered"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Date création</th>
                  <th>Nombre de noeuds</th>
                  <th>Nombre de liens</th>
                  <th>Éléments manquants</th>
                  <th>Onotology ID</th>
                  <th>Action</th> 
                </tr>
              </thead>
              <tbody>
                {tiddlyMapExports.map((data, index) => (
                  <tr 
                    style={{opacity: index === 0 ? undefined : 0.4}}
                    className={data.attributes.obsolete ? 'table-warning' : 'table-success'}
                    title={data.attributes.obsolete && 'Exportation obsolète'}
                  >
                    
                    
                      <td>
                      {data.attributes.obsolete && <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 text-danger" />}

                      {data.id}
                      </td>
                      <td>{data.attributes.created_at}</td>
                      <td>{data.attributes.json_content.nodes.length}</td>
                      <td>{data.attributes.json_content.edges.length}</td>
                      <td>{data.attributes.missing_log?.length}</td>
                      <td>{data.attributes.ontology_hash}</td>
                      <td>
                        <Link to={`/tiddly_map_exports/${data.id}`}>
                          <Button className="mx-1 btn-sm btn btn-success">Consulter</Button>
                        </Link>
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </Page.Content>
    </SiteWrapper>
  )
}

export default TiddlyMapExport